// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDZTftzEe7nqyjrei0cA6fwqPVAavYG2oI",
    authDomain: "content-manager-80519.firebaseapp.com",
    projectId: "content-manager-80519",
    storageBucket: "content-manager-80519.appspot.com",
    messagingSenderId: "587167202952",
    appId: "1:587167202952:web:92c59d6a9e0f3576a7693d"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export function FirebaseApp(){
    return initializeApp(firebaseConfig);
}
const storage = getStorage(app);
const analytics = getAnalytics(app);
export {storage};
