import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { storage } from "../../firebase";
import CircularProgress from "@mui/material/CircularProgress";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import axios from "axios";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";

export default function CompetitionFee(props) {
  const [hide, setHide] = useState(false);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);
  // console.log(props.teamId);
  const [file, setFile] = useState(null);
  const { currentUser } = useAuth();
  const [percent, setPercent] = useState(0);
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  async function handleUpload(e) {
    setHide(true);

    if (!file) {
      alert("Please upload a file first!");
      setHide(false);
      return;
    }

    const storageRef = ref(storage, `/files/${file.name}-${Date.now()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setPercent(percent);
      },
      (err) => {
        console.error(err, "error");
        setHide(false);
      },
      async () => {
        try {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          console.log(url, "url got");

          const data = {
            email: currentUser.email,
            imageurl: url,
          };

          console.log("calling file api");

          await axios.post("https://backend-for-user-side.onrender.com/api/competitionfeeupload", data);
          setHide(false);
          await window.location.reload();
        } catch (err) {
          console.error(err);
          setHide(false);
        }
      }
    );
  }

  return (
    <div>
      <h4
        style={{
          color: "#00d0ff",
          fontWeight: "bold",
          fontSize: "22px",
          marginRight: "10px",
        }}
      >
        Submit receipt:
      </h4>
      <Stack direction="row" spacing={3}>
        <input
          className="button-68"
          type="file"
          onChange={handleChange}
          accept="/image/*"
        />

        <Button
          disabled={hide}
          className="button-68"
          onClick={handleUpload}
          style={{ padding: "5px" }}
        >
          Upload
        </Button>
        <CircularProgress
          color="success"
          variant="determinate"
          value={percent}
        />
      </Stack>
    </div>
  );
}
