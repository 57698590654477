import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './payment.css';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height:500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PaymentModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log("pay");
  return (
    <div>
      <Button onClick={handleOpen}>{props.name}</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h4" component="h2" sx={{ mb: 2 }}>
              Payment Procedure
            </Typography>
            <p id="transition-modal-description" variant="h7" sx={{ mt: 2 }}>
            {/* Team Techkriti is back with another exciting opportunity for tech enthusiasts like you.<br/>
                <p>You are registered for workshops in Techkriti'24. You may now proceed toward fee payment. 
                </p> */}
              Will be updated soon
            </p>
            {/* <table style={{borderColor:'#fff',border:'1px solid', borderCollapse:'collapse', width:'100%'}}>
                <tr>
                    <th style={{border:'1px solid', borderCollapse:'collapse'}}>The normal workshop fee : </th>
                    <th style={{border:'1px solid', borderCollapse:'collapse',color:"#00FFFF", textDecoration:'none'}}>INR 1400/-</th>
                </tr>
                <tr>
                    <td style={{border:'1px solid', borderCollapse:'collapse'}}>The accommodation fee for Techkriti'23 : </td>
                    <th style={{border:'1px solid', borderCollapse:'collapse',color:"#00FFFF"}}>INR 2500/-</th>
                </tr>
                <tr>
                    <td style={{border:'1px solid', borderCollapse:'collapse'}}>Guide on how to use SBI-ICollect portal</td>
                    <th style={{border:'1px solid', borderCollapse:'collapse',color:"#00FFFF", textDecoration:'none'}}><a href='https://docs.google.com/document/d/1AFh_7s4IDoJk0TN7nN18J1345TQKpYmYi7Hjx2Sx9H4/edit?usp=sharing' target="_blank">click here</a></th>
                </tr>
                <tr>
                    <td style={{border:'1px solid', borderCollapse:'collapse'}}>Video guide of the portal:</td>
                    <th style={{border:'1px solid', borderCollapse:'collapse',color:"#00FFFF", textDecoration:'none'}}><a href='https://drive.google.com/file/d/1yUFlkjts-4oQ0J5Sys-2ehgy6w2G-Xs0/view?usp=sharing'  target="_blank">click here</a></th>
                </tr>
                <tr>
                    <td >Deadline for paying the hospitality and workshop Fee:</td>
                    <th style={{border:'1px solid', borderCollapse:'collapse',color:"red", textDecoration:'none'}}>10th March 2023</th>
                </tr>
                </table>
            <Typography id="transition-modal-description" variant="h7" sx={{ mt: 2 }}>
            Details regarding workshops can be found here : <a href='#'>details</a>
            </Typography><br/>
            <Typography id="transition-modal-description" variant="h7" sx={{ mt: 2 }}>
            Also, those who pay the fees before the 5th of march or the first 250 participants will get to attend 1 free workshop from CISCO, topics for these workshops would be the Internet of Things and BlockChain Technologies.            </Typography> */}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}