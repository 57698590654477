import React, { useEffect } from "react";
import "./brochure.css";
const Brochure = () => {
  const Links = [
    "https://i.postimg.cc/RVxDbC7L/1.png",
    "https://i.postimg.cc/hjH5ncHg/2.png",
    "https://i.postimg.cc/V6SV2dZD/3.png",
    "https://i.postimg.cc/GpgfMkK4/4.png",
    "https://i.postimg.cc/yN85Xfmq/5.png",
    "https://i.postimg.cc/DwgHGVbd/6.png",
    "https://i.postimg.cc/MZQLDfQG/7.png",
    "https://i.postimg.cc/nrBwcNJm/8.png",
    "https://i.postimg.cc/fRwgDHg3/9.png",
    "https://i.postimg.cc/x1tpCKW6/10.png",
    "https://i.postimg.cc/7LhsT54w/11.png",
    "https://i.postimg.cc/0N4tG95m/12.png",
    "https://i.postimg.cc/wvsw0n3r/13.png",
    "https://i.postimg.cc/0jRc3Zwy/14.png",
    "https://i.postimg.cc/GtFq1YFJ/15.png",
    "https://i.postimg.cc/pVz08Nwh/16.png",
    "https://i.postimg.cc/7Y4Kns4B/17.png",
    "https://i.postimg.cc/28x2cQ8C/18.png",
    "https://i.postimg.cc/CxLJWRzk/19.png",
    "https://i.postimg.cc/5tXg2D5t/20.png",
    "https://i.postimg.cc/QMvb9SSJ/21.png",
    "https://i.postimg.cc/3RpZgpc5/22.png",
    "https://i.postimg.cc/NfQxHK8S/23.png",
    "https://i.postimg.cc/PrKQzd2x/24.png",
    "https://i.postimg.cc/7Z6MKjWN/25.png",
    "https://i.postimg.cc/CxQCXmyp/26.png",
    "https://i.postimg.cc/CKnCgkYB/27.png",
    "https://i.postimg.cc/Tw8VY1Yk/28.png",
    "https://i.postimg.cc/ZRrr9mWJ/29.png",
    "https://i.postimg.cc/7hwS0Rz0/30.png",
    "https://i.postimg.cc/SRdCrGL5/31.png",
    "https://i.postimg.cc/zDFC8Jmj/32.png",
    "https://i.postimg.cc/vH8Wmpbd/33.png",
    "https://i.postimg.cc/kXjWRfgR/34.png",
    "https://i.postimg.cc/526LBLX7/35.png",
    "https://i.postimg.cc/L59Zn1dV/36.png",
    "https://i.postimg.cc/d35ZdVwc/37.png",
    "https://i.postimg.cc/NGDHZpTC/38.png",
    "https://i.postimg.cc/rFprTxNV/39.png",
    "https://i.postimg.cc/PrF85JSm/40.png",
    "https://i.postimg.cc/Qd9K9Dzv/41.png",
    "https://i.postimg.cc/fRGtYFny/42.png",
    "https://i.postimg.cc/pdK9CZRZ/43.png",
    "https://i.postimg.cc/qR0N537d/44.png",
    "https://i.postimg.cc/RVxDbC7L/1.png",
    
  ];
  
  const handleBrochureClick = () => {
    console.log("hello");
  };
  return (
    <div>
      <video
        className="landing-video"
        autoPlay
        loop
        muted
        playsInline
        id="myVideo"
      >
        <source src="https://ik.imagekit.io/l4xjlvnpr/WhatsApp_Video_2023-02-12_at_2.49.47_PM.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1676314302663" />
      </video>
      <section class="brochure-gallery" >
        {/* <div>
          <img src="" alt="" />
        </div> */}
        {/* <div class="gallery__item">
            <input
              type="radio"
              id={`img-` + 1}
              checked
              name="gallery"
              class="gallery__selector"
              // onClick={handleBrochureClick}
            />
            <img class="gallery__img" src={Links[0]} width="1000vw" />
            <label
              for={"img-" + 1}
              class="gallery__thumb dep-gallery-bottom"
              style={{ height: "50px",left:72*0 + "px", width: "70px", zIndex: "990" }}
              // onClick={handleBrochureClick}

              // style={{ height: "50px", width: 100/20 +"vw",left:index*(100/20)+"vw",zIndex:"990"}}
            >
              <img src={Links[0]} alt="" onClick={console.log("ok")} />
            </label>
            <div />
          </div> */}
        {Links.map((link, index) => (
          <div class="gallery__item">
            <input
              type="radio"
              id={`img-` + index}
              checked
              name="gallery"
              class="gallery__selector"
              // onClick={handleBrochureClick}
            />
            <img class="gallery__img" src={link} width="1000vw" />
            <label
              for={"img-" + index}
              class="gallery__thumb dep-gallery-bottom"
              style={{ height: "43px",left:72*index + "px", width: "70px", zIndex: "990" }}
              // onClick={handleBrochureClick}

              // style={{ height: "50px", width: 100/20 +"vw",left:index*(100/20)+"vw",zIndex:"990"}}
            >
              <img src={link} alt="" onClick={console.log("ok")} />
            </label>
            <div />
          </div>
        ))}
        <div className="brochure-footer"></div>
      </section>
    </div>
  );
};

export default Brochure;
