import React from "react";
import "./Dashboard1.css";
import { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import FileUpload from "./FileUpload";
import { Button } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Alert } from "react-bootstrap";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function RegComps(props) {
  const [teams, setTeams] = useState([]);
  const { currentUser } = useAuth();
  const [error, setError] = useState("")
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [present, setpresent] = useState(false);
  useEffect(() => {
    axios
      .post(
        `https://backend-for-user-side.onrender.com/api/get-user-teams`, { useremail: currentUser.email }
      )
      .then((response) => {
        setTeams(response.data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    console.log(teams)
    setpresent(teams.length > 0);
  }, [teams]);

  //// Team edit controls and states
  const [leaveteamdialog, setteamleavedialog] = useState(false);
  const [addmemberdialog, setaddmemberdialog] = useState(false);
  const [removememberdialog, setremovememberdialog] = useState(false);
  const [deleteteamdialog, setdeleteteamdialog] = useState(false);
  const [isadding, setisadding] = useState(false);
  const [isremoving, setisremoving] = useState(false);
  const [isdeleting, setisdeleting] = useState(false);
  const [isleaving, setisleaving] = useState(false);
  const [addusermail, setaddusermail] = useState("");
  const [removeusermail, setremoveusermail] = useState("");
  const [newleadermail, setnewleadermail] = useState("");

  ///// Team edit functions 
  const handleAddmember = async (team) => {
    setisadding(true);
    await axios
      .post(
        `https://backend-for-user-side.onrender.com/api/join-a-team`,
        {
          teamId: team.teamId,
          useremail: addusermail,
          compname: team.competition_id
        }
      )
      .then((response) => {
        if (response.data.message === "false") setError("Already a part of this team");
        else if (response.data.message === "limitCross") setError("Team Size limit exceeded");
        else if (response.data.message === "invalid id") setError(`No team with this TeamID exists for ${team.competition_id}`)
        else if(response.data.message === "pehle hi h") setError(`User is already registered for the competition`);
        else if(response.data.message === "3 ho gye") setError(`User is already registered in 3 competitions`)
        else { setisadding(false); setaddmemberdialog(false); window.location.reload(); }

      })
      .catch((err) => {
        // console.log(err.response.data.error)
        setError(`${err.response.data.error}`);
      });
    setisadding(false);
  }

  const handleRemoveMember = async (team) => {
    setisremoving(true);
    await axios
      .post(
        `https://backend-for-user-side.onrender.com/api/leave-a-team`,
        {
          teamId: team.teamId,
          useremail: removeusermail,
        }
      )
      .then((response) => {
        if (response.data.message === "false") setError("Not a part of this team");
        else { setisremoving(false); setremovememberdialog(false); window.location.reload(); }
      })
      .catch((err) => {
        setError("Error registreing");
      });
    setisremoving(false);
  }

  const handleDeleteTeam = async (team) => {
    setisdeleting(true);
    await axios
      .post(
        `https://backend-for-user-side.onrender.com/api/delete-a-team`,
        {
          teamId: team.teamId,
        }
      )
      .then((response) => {
        { setisdeleting(false); setdeleteteamdialog(false); window.location.reload(); }
      })
      .catch((err) => {
        setError("Error registreing");
      });
    setisdeleting(false);

  }
  const handleleaveforleader = async (team) => {
    setisleaving(true);
    await axios
      .post(
        `https://backend-for-user-side.onrender.com/api/leave-leader`,
        {
          teamId: team.teamId,
          newleadermail: newleadermail
        }
      )
      .then((response) => {
        if(response.data.message === "false"){setError("The new leader is not a team member!")}
        { setisleaving(false); setteamleavedialog(false); window.location.reload(); }
      })
      .catch((err) => {
        setError("Error registreing");
      });
    setisleaving(false);
  };

  const handleLeave = async (team) => {
    setisleaving(true);
    await axios
      .post(
        `https://backend-for-user-side.onrender.com/api/leave-a-team`,
        {
          teamId: team.teamId,
          useremail: currentUser.email,
        }
      )
      .then((response) => {
        if (response.data.message === "false") setError("Not a part of this team");
        else { setisleaving(false); setteamleavedialog(false); window.location.reload(); }
      })
      .catch((err) => {
        setError("Error registreing");
      });
    setisleaving(false);
  };

  const returnData = () => {
    return (
      <>
        {present ? (
          teams?.map((employee) => {
            if (employee !== null) {
              return (
                <div
                  style={{
                    border: "solid 0.1px",
                    marginTop: "3%",
                    paddingLeft: "2%",
                    borderColor: "#24B8DB",
                    backgroundColor: "rgba(36,184,219,0.08)",
                    zIndex: "100",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      grid: "auto auto / auto auto auto auto",
                      gridGap: "10px",
                      paddingTop: "2%",
                    }}
                    className="regcompTeamDetail"
                  >
                    <div>
                      <h2 className="regcompProfileHead">
                        Team Name:{" "}
                        <span className="regcompProfileSpan">
                          {employee.name}
                        </span>
                      </h2>
                    </div>
                    <div>
                      <h2 className="regcompProfileHead">
                        Team Id:{" "}
                        <span className="regcompProfileSpan">
                          {employee.teamId}
                        </span>
                      </h2>
                    </div>
                    <div>
                      <h2 className="regcompProfileHead">
                        Contest:{" "}
                        <span className="regcompProfileSpan">
                          {employee.competition_id}
                        </span>
                      </h2>
                    </div>
                  </div>
                  <hr class="sep-2" />

                  <h2 className="regcompProfileHead">
                    Leader Name:{" "}
                    <span className="regcompProfileSpan">
                      {employee.leader_name}
                    </span>
                  </h2>

                  <hr class="sep-2" />
                  <h4 className="regcompProfileHead">
                    Member Names:
                    {employee.member_names?.map((e) => {
                      return (
                        <span key={employee.id} className="regcompProfileSpan">
                          {" "}
                          <td
                            style={{ padding: "10px 5px" }}
                            className="button-1 member-names"
                          >
                            {e}
                          </td>
                        </span>
                      );
                    })}
                  </h4>
                  <hr class="sep-2" />
                  {employee.leader_name === props.username &&
                    <>
                      <div
                        style={{
                          display: "flex",
                          grid: "auto auto / auto auto auto auto",
                          gridGap: "10px",
                          paddingTop: "2%",
                        }}
                        className="regcompTeamDetail"
                      >
                        {employee.member_names?.length < 6 && <Button onClick={() => setaddmemberdialog(true)}>Add Member to team</Button>}
                        <Button onClick={() => setremovememberdialog(true)}>Remove Member from team</Button>
                        <Button onClick={() => setdeleteteamdialog(true)}>Delete Team</Button>
                      </div>
                      <hr class="sep-2" />
                    </>
                  }
                  <Button onClick={() => setteamleavedialog(true)}>Leave Team</Button>
                  <hr class="sep-2" />
                  {/* different Dialogs */}
                  <Dialog open={addmemberdialog} onClose={() => setaddmemberdialog(false)}>
                    <DialogTitle>Add a new Member to the team</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Enter the email id of the user you want to add. Please ensure beforehand that the user is already registered with us and has updated his/her profile.
                      </DialogContentText>
                      <TextField
                        label="Email id"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={addusermail}
                        onChange={(e) => setaddusermail(e.target.value)}
                        error={!!error && !addusermail} // Show error if error exists and college is empty
                        helperText={(error && !addusermail) ? error : ''}
                        required
                      />
                      <Alert variant="error">{error ? error : ""}</Alert>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setaddmemberdialog(false)} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={() => handleAddmember(employee)} disabled={isadding} color="primary">
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog open={removememberdialog} onClose={() => setremovememberdialog(false)}>
                    <DialogTitle>Remove member from team</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Enter the email id of the person you want to remove from the team. Please don't enter your own mail id. To remove yourself, use the Leave Team option.
                      </DialogContentText>
                      <TextField
                        label="Email id"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={removeusermail}
                        onChange={(e) => setremoveusermail(e.target.value)}
                        error={!!error && !removeusermail} // Show error if error exists and college is empty
                        helperText={(error && !removeusermail) ? error : ''}
                        required
                      />
                      <Alert variant="error">{error ? error : ""}</Alert>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setremovememberdialog(false)} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={() => handleRemoveMember(employee)} disabled={isremoving} color="primary">
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog open={deleteteamdialog} onClose={() => setdeleteteamdialog(false)}>
                    <DialogTitle>Delete the team</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Do you want to delete the team? Think twice, the action is irreversible and it will put all the participants out of the competition.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setdeleteteamdialog(false)} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={() => handleDeleteTeam(employee)} disabled={isdeleting} color="primary">
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog open={leaveteamdialog} onClose={() => setteamleavedialog(false)}>
                    <DialogTitle>Leave the team</DialogTitle>

                    {employee.member_names.length === 1 && <><DialogContent><DialogContentText>You are the only person in the team. Use the delete team option instead.</DialogContentText></DialogContent>
                      <DialogActions>
                        <Button onClick={() => setteamleavedialog(false)} color="primary">
                          Cancel
                        </Button></DialogActions></>
                    }

                    {employee.member_names.length > 1 && currentUser.email === employee.leader_email && <><DialogContent> <DialogContentText>Since you are the leader of the team, enter the email of the person you want to be the next leader.
                    </DialogContentText>
                      <TextField
                        label="Email id"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={newleadermail}
                        onChange={(e) => setnewleadermail(e.target.value)}
                        error={!!error && !newleadermail} // Show error if error exists and college is empty
                        helperText={(error && !newleadermail) ? error : ''}
                        required
                      />
                    </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setteamleavedialog(false)} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={() => handleleaveforleader(employee)} disabled={isleaving} color="primary">
                          Confirm
                        </Button>
                      </DialogActions></>
                    }
                    {employee.member_names.length > 1 && currentUser.email !== employee.leader_email && <><DialogContent> <DialogContentText>Do you surely want to leave the team?
                    </DialogContentText>
                    </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setteamleavedialog(false)} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={() => handleLeave(employee)} disabled={isleaving} color="primary">
                          Confirm
                        </Button>
                      </DialogActions></>
                    }
                  </Dialog>


                  {
                    employee.abstract_confirm === "pending" &&
                    <>Abstract Submitted and Pending for Verification</>
                  }
                  {
                    employee.abstract_confirm === "false" &&
                    <FileUpload teamId={employee.teamId} value="abstract" />
                  }
                  {
                    employee.abstract_confirm === "reject" && <>
                    <p>You abstract didn't meet our expectations. Kindly submit it again.</p>
                    <FileUpload teamId={employee.teamId} value="abstract" />
                    </>
                  }
                  {/* {employee.abstract_confirm === "verified" ? <Button onClick={handleOpen} sx={{ mb: 3 }}>Go to Payment</Button> : <></>}
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box sx={style}>
                        Payment Details:

                      </Box>
                    </Fade>
                  </Modal> */}

                  <br />
                </div >
              );
            }
          })
        ) : (
          <>Not registered in any teams yet</>
        )}
      </>
    );
  };
  const returnNoData = () => {
    return (
      <>
        <h3>Not registered in any competitions </h3>
      </>
    );
  };

  return (
    <div>
      {present && returnData()}
      {!present && returnNoData()}
    </div>
  );
}
