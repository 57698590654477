import React from "react";
import {Link} from "react-router-dom";



class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}
	
	static getDerivedStateFromError(error) {
		return {hasError: true};
	}
	
	componentDidMount() {
		document.body.style.backgroundColor = "#000";
	}
	
	render() {
		if (this.state.hasError) {
			return (<div style={{width: "100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
				<h1>Oops! Something went wrong.</h1>
				<Link to="/"><h2 style={{color:"blue"}}>Return to home</h2></Link>
				<h2 style={{color:"blue", cursor:"pointer"}} onClick={() => {this.setState({hasError: false}); window.location.reload();}}>Refresh this page</h2>
			</div>)
		}
		return this.props.children;
	}
};

export default ErrorBoundary;