import React, { useState, useEffect, Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "./Components/MediaQuery";
import ErrorBoundary from "./Components/ErrorBoundary";
import ReactGA from "react-ga";

import loadervid from "./loader4.mp4";
import Brochure from "./Components/Brochure/Brochure";
import Dashboard  from "./Components/Dashboard/Dashboard.js";

const CarouselFadeExample = lazy(() =>
  import("./Components/Carousel/carousel")
);
const Update = lazy(() => import("./Components/Update"));
const Register = lazy(() => import("./Components/Register/Register"));
const WorkshopTemplate = lazy(() =>
  import("./Components/Workshop/WorkshopTemplate")
);
const RegisterWorkshop = lazy(() => import("./Components/RegisterWorkshop"));
const Sponsor = lazy(() => import("./Components/sponsors/Sponsor"));
const About = lazy(() => import("./Components/about/About"));
const Enquiry = lazy(() => import("./Components/enquiry/Enquiry"));
const Competition = lazy(() =>
  import("./Components/Competitions/Competition.jsx")
);
const Workshop = lazy(() => import("./Components/Workshop/Workshop"));
const Merchandise = lazy(() => import("./Components/Merchandise/Merchandise"));
const Sidenav = lazy(() => import("./Components/Layout/sidenav"));
const Contact = lazy(() => import("./Components/Contact/Contact"));
const Home = lazy(() => import("./Components/Home/Home"));
// const Dashboard = lazy(() => import("./Components/Dashboard/Dashboard"));
const NotFound = lazy(() => import("./Components/404.js"));
const Hospitality = lazy(() => import("./Components/Hospitality/page.js"));
const JoinTeam = lazy(() => import("./Components/Register/JoinTeam.js"));
const Social = lazy(() => import("./Components/Social-initiative/Social.js"));
const Talk = lazy(() => import("./Components/talks/Talk.js"));
const Shownew = lazy(() => import("./Components/shows/showNew.js"));
const Exhibitions = lazy(() =>
  import("./Components/exhibitions/Exhibitions.js")
);
const Megaworld = lazy(() => import("./Components/megaworld/Megaworld.js"));
const Galleryee = lazy(() => import("./Components/gallery/newgallery.js"));
const Schedule = lazy(() => import("./Components/Schedule/Schedule.js"));
const CompetitionDetail = lazy(() => import("./Components/Competitions/subpages/ContestDetails.js"));
const CompHome = lazy(() => import("./Components/Competitions/subpages/comphome.js"))
function Loader() {
  return (
  	<div style={{width: "100vw", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
	<video autoPlay loop={true} playsInline muted key="loader" style={{}}>
		<source src={loadervid} />
		<div style={{ width: "100%", height: "100%", display: "flex" }}>
		  <div className="suspense-fallback-loader"></div>
		</div>
	</video>
	</div>
  );
}

console.log = function(){};

function App() {
  const [themeColor, setThemeColor] = useState("#000000");
  const [lowergridmenu, setLowergridmenu] = useState([]);
  const big = useMediaQuery("(min-width:900px)");
  const setTheme = (color) => {
    document.body.style.backgroundColor = color;
    setThemeColor(color);
    //console.log("body bg set");
  };

  const setLowergrid = (array) => {
    setLowergridmenu(array);
  };

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
    typography: {
      fontFamily: '"Montserrat","Arial",sans-serif',
      fontSize: 20,
      htmlFontSize: 20,
      button: {
        textTransform: "none",
        fontFamily: '"Montserrat","Arial",sans-serif',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(69, 89, 112, 0.35)",
            color: "#fff",
            border: "1px solid #fff",
            textDecoration: "none",
            "&:hover": {
              backgroundColor: "rgba(69, 89, 112, 0.8)",
            },
            "&.Mui-disabled": {
              backgroundColor: "rgba(69, 89, 112, 0.8)",
              color: "#888",
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: "16pt",
            color: "#fff",
            opacity: 1,
            padding: "7px",
            margin: "0 10px 0",
            "&.Mui-selected": {
              color: "#fff !important",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            width: "100%",
            height: "100%",
            "& a": {
              flexShrink: 0,
            },
          },
          indicator: {
            backgroundColor: "#fff",
            height: "100%",
            opacity: 0.5,
            alignItems: "center",
          },
          scroller: {
            width: "90%",
            height: "100%",
            flexGrow: "0",
          },
          flexContainer: {
            height: "100%",
            alignItems: "center",

            justifyContent: "space-between",
            "& button": {
              height: "100%",
            },
          },
          scrollButtons: {
            width: "15px",
          },
          vertical: {
            "& .MuiTabs-indicator": {
              width: "100%",
            },
          },
        },
        defaultProps: {
          allowScrollButtonsMobile: true,
          variant: "scrollable",
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#fff",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          input: {
            color: "white",
            borderColor: "white",
            border: "1px solid #ced4da",
          },
        },
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader />}>
        <Router>
          <AuthProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <Sidenav
                    themeColor={themeColor}
                    lowergridmenu={lowergridmenu}
                    setThemeColor={setTheme}
                    setLowergridmenu={setLowergrid}
                    big={big}
                  />
                }
              >
                <Route
                  path="/"
                  element={
                    <ErrorBoundary key="Home">
                      <Home
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/gallery"
                  element={
                    <ErrorBoundary key="Galleryee">
                      <Galleryee
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/social-initiatives"
                  element={
                    <ErrorBoundary key="Social">
                      <Social
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/hospitality"
                  element={
                    <ErrorBoundary key="Hospitality">
                      <Hospitality
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/merchandise"
                  element={
                    <ErrorBoundary key="Merchandise">
                      <Merchandise
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/jointeam/:events"
                  element={
                    <ErrorBoundary key="JoinTeam">
                      <JoinTeam
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/workshops"
                  element={
                    <ErrorBoundary key="Workshop">
                      <Workshop
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/merchandise"
                  element={
                    <ErrorBoundary key="Merchandise">
                      <Merchandise
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/sponsors"
                  element={
                    <ErrorBoundary key="Sponsor">
                      <Sponsor
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/enquiry"
                  element={
                    <ErrorBoundary key="Enquiry">
                      <Enquiry
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/megaworld"
                  element={
                    <ErrorBoundary key="Megaworld">
                      <Megaworld
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/shows"
                  element={
                    <ErrorBoundary key="Show">
                      <Shownew
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/exhibitions"
                  element={
                    <ErrorBoundary key="Exhibitions">
                      <Exhibitions
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/brochure"
                  element={
                    <ErrorBoundary key="Brochure">
                      <Brochure
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/about"
                  element={
                    <ErrorBoundary key="About">
                      <About
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/talkseries"
                  element={
                    <ErrorBoundary key="Talk">
                      <Talk
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/register-w/:workName"
                  element={
                    <RegisterWorkshop
                      setThemeColor={setTheme}
                      setLowergridmenu={setLowergrid}
                    />
                  }
                />

                <Route path="/competitions/">
                  <Route
                    index
                    element={
                      <Suspense fallback={<Loader />}>
                        <CompHome
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                          category=""
                          big={big}
                        />
                      </Suspense>
                    }
                  />
                  <Route 
                    path = ":category"
                    index
                    element={
                      <Suspense fallback={<Loader />}>
                        <Competition
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                          category=""
                          big={big}
                        />
                      </Suspense>
                    }
                  />
                  {/* <Route path="technical/">
                    <Route
                      index
                      element={
                        <Suspense fallback={<Loader />}>
                          <Competition
                            setThemeColor={setTheme}
                            setLowergridmenu={setLowergrid}
                            category="Technical"
                            big={big}
                          />
                        </Suspense>
                      }
                    />
                  </Route>
                  <Route path="entrepreneurial/">
                    <Route
                      index
                      element={
                        <Suspense fallback={<Loader />}>
                          <Competition
                            setThemeColor={setTheme}
                            setLowergridmenu={setLowergrid}
                            category="Entrepreneurial"
                            big={big}
                          />
                        </Suspense>
                      }
                    />
                  </Route>
                  <Route path="miscellaneous/">
                    <Route
                      index
                      element={
                        <Suspense fallback={<Loader />}>
                          <Competition
                            setThemeColor={setTheme}
                            setLowergridmenu={setLowergrid}
                            category="Miscellaneous"
                            big={big}
                          />
                        </Suspense>
                      }
                    />
                  </Route> */}

  

                    {/* <Route
                      path=":params"
                      element={
                        <Suspense fallback={<Loader />}>
                          <Competition
                            setThemeColor={setTheme}
                            setLowergridmenu={setLowergrid}
                            category="Entrepreneurial"
                            big={big}
                          />
                        </Suspense>
                      }
                    /> */}

                  

                  <Route path="details/">
                    <Route
                      index
                      element={
                        <Suspense fallback={<Loader />}>
                          <Competition
                            setThemeColor={setTheme}
                            setLowergridmenu={setLowergrid}
                            category="Details"
                            big={big}
                          />{" "}
                        </Suspense>
                      }
                    />

                    <Route
                      path=":params"
                      element={
                        <Suspense fallback={<Loader />}>
                          <CompetitionDetail
                            setThemeColor={setTheme}
                            setLowergridmenu={setLowergrid}
                            big={big}
                          />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>

                <Route
                  path="/contact-us"
                  element={
                    <ErrorBoundary key="Contact">
                      <Contact
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/profile"
                  element={
                    <ErrorBoundary key="Dashboard">
                      <Dashboard
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/update"
                  element={
                    <ErrorBoundary key="Update">
                      <Update
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/register/:events"
                  element={
                    <ErrorBoundary key="Register">
                      <Register setThemeColor={setTheme} />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/workshop/:workName"
                  element={
                    <ErrorBoundary key="WorkshopTemplate">
                      <WorkshopTemplate setThemeColor={setTheme} />
                    </ErrorBoundary>
                  }
                />
                
                <Route
            		path="/schedule"
            		element={
            		<ErrorBoundary key="Schedule">
                      <Schedule setThemeColor={setTheme} />
                    </ErrorBoundary>
            		}/>

                <Route
                  path="*"
                  element={
                    <ErrorBoundary key="404">
                      <Suspense fallback={<div></div>}>
                        <NotFound
                          setThemeColor={setTheme}
                          setLowergridmenu={setLowergrid}
                        />
                      </Suspense>
                    </ErrorBoundary>
                  }
                />
              </Route>
            </Routes>
          </AuthProvider>
        </Router>
    	</Suspense>
      </ThemeProvider>
    </>
  );
}

export default App;
