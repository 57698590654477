import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./alphadashboard.css";
// import comp from "../images/comp1.png";
import { Tabs, Tab, Button } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useAuth } from "../../context/AuthContext";
import RegComps from "./RegComps";
import PaymentModal from "../Payment/Payment";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CompetitionFee from "./Competition_Fee";
import Workshopfee from "./Workshop_fee";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  console.log("component rendered")
  const navigate = useNavigate();
  const [error, setError] = useState("")
  useEffect(() => {
    props.setThemeColor("#000"); //on mount: set theme to black
    props.setLowergridmenu([]);
  }, []);


  const { currentUser, logout } = useAuth();
  const [loading, setloading] = useState(true);
  const [currentUserInfo, setCurrentUserInfo] = useState(null);
  const avatar = createAvatar(identicon, {
    // ... options
    seed: "Sammy",
  });
  const json = avatar.toJson();
  console.log(json);
  const [value, setValue] = React.useState(0);
  const [show, setShow] = useState(false);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
    setShow(false);
  };
  useEffect(() => {
    console.log("calling api")
    axios
      .post("https://backend-for-user-side.onrender.com/api/getuserdata", {
        email: currentUser?.email,
      })
      .then((response) => {
        console.log(response.data)
        setCurrentUserInfo((response.data));
        setloading(false)
        console.log(currentUserInfo)
      })
      .catch((e) => console.log(e));
  }, [currentUser.email]);

  useEffect(() => {
    if (currentUserInfo && !(currentUserInfo.isUpdateProfile)) {
      setState({ ...state, open: true });
      setShow(true);
      console.log("currentUserInfo changed:", currentUserInfo.isUpdateProfile);
    }
  }, [currentUserInfo]);

  async function handleLogout() {
    try {
      setError("");
      await logout();
      navigate('/');
    } catch {
      setError("Failed to logout");
    }
  }

  if (!currentUser) {
    navigate("/");
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        className="page-background profile-contest-details-background"
        style={{}}
      ></div>
      <div className="main-container">
        {show && <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          open={show}
          onClose={handleClose}
          message="I love snacks"
          key={vertical + horizontal}
        >
          <Alert severity="success" sx={{ width: "100%", mt: 8 }}>
            Check your email for further information
          </Alert>
        </Snackbar>
        }
        <div className="profile-contest-details-container">
          <div className="profile-contest-details-tabs">
            <Tabs value={value} onChange={handleChange}>
              <Tab
                style={{ fontSize: "15px" }}
                label="Profile"
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontSize: "15px" }}
                label="Festival Fee Payment"
                {...a11yProps(1)}
              />
              <Tab
                style={{ fontSize: "15px" }}
                label="Registered Competitions"
                {...a11yProps(2)}
              />

              <Tab
                style={{ fontSize: "15px" }}
                label="Registered Workshops"
                {...a11yProps(3)}
              />
            </Tabs>
          </div>
          <div className="profile-contest-details-content">
            <TabPanel
              value={value}
              index={0}
              style={{ display: "flex", alignItems: "Center", width: "100%" }}
            >

              {!loading && <><div className="profile-contest-details-inner">
                <h3 className="regcompProfileHead">
                  Name:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.name}
                  </span>
                </h3>
                <h3 className="regcompProfileHead">
                  TechId:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.techid}
                  </span>
                </h3>
                <h3 className="regcompProfileHead">
                  EmailId:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.email}
                  </span>
                </h3>
                <h3 className="regcompProfileHead">
                  College:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.college}
                  </span>
                </h3>
                <h3 className="regcompProfileHead">
                  Phone:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.phone}
                  </span>
                </h3>
                <h3 className="regcompProfileHead">
                  City:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.collegeCity}
                  </span>
                </h3>
                <h3 className="regcompProfileHead">
                  State:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.collegeState}
                  </span>
                </h3>
                <h3 className="regcompProfileHead">
                  Festival Fee Verification Status:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.feepaid === 1 && "Not Submitted"}
                    {/* {currentUserInfo?.competition.length !== 0 && currentUserInfo?.feepaid === 1 && "Not Submitted"} */}
                    {currentUserInfo?.competition.length !== 0 && currentUserInfo?.feepaid === 2 && <span style={{ "color": "red" }}>Pending</span>}
                    {currentUserInfo?.competition.length !== 0 && currentUserInfo?.feepaid === 3 && <span style={{ "color": "red" }}>Rejected</span>}
                    {currentUserInfo?.competition.length !== 0 && currentUserInfo?.feepaid === 4 && <span style={{ "color": "#44c795" }}>Verified</span>}
                  </span>
                </h3>
                <h3 className="regcompProfileHead">
                  Year of Studying:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.yearStudy}
                  </span>
                </h3>
                <h3 className="regcompProfileHead">
                  Gender:{" "}
                  <span className="regcompProfileSpan">
                    {currentUserInfo?.gender}
                  </span>
                </h3>
              </div>
                <div className="profile-contest-details-img">
                  <img
                    src={`https://api.dicebear.com/5.x/identicon/svg?seed=${currentUserInfo?.email}&rowColor=00acc1&backgroundColor=b6e3f4`}
                    alt="avatar"
                  />
                  {/* {svg} */}
                </div>
                <div className="profile-button-highlights">
                  <Link
                    to={"/update"}
                    style={{ color: "white", marginRight: "20px" }}
                  >
                    <button className="profile-contest-details-register dash-button">
                      <div className="profile-contest-details-register dash-button" />

                      Update Profile

                    </button>
                  </Link>
                  <button
                    className="profile-contest-details-other dash-button"
                    onClick={handleLogout}
                    style={{ paddingRight: "20px" }}
                  >
                    <div className="profile-contest-details-other dash-button" />
                    Logout
                  </button>
                </div>
              </>}
              {loading && <p>Loading user information....</p>}
            </TabPanel>

            <TabPanel
              value={value}
              index={2}
              className="regCompTabpanel"
              style={{ height: "80%", width: "100%", overflow: "auto" }}
            >
              <div
                className="profile-contest-details-inner"
                style={{ width: "100%", height: "85%" }}
              >
                <h2
                  className="profile-contestDetailHead"
                  style={{ fontSize: "25px" }}
                >
                  You are registered in the following competitions:
                </h2>
                <div
                  className="regCompTabpanel"
                  style={{ height: "80%", overflow: "auto", padding: "5px" }}
                >
                  <RegComps username={currentUserInfo?.name} />
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              className="regCompTabpanel"
              style={{ height: "80%", width: "100%", overflow: "auto" }}
            >
              <div
                className="profile-contest-details-inner"
                style={{ width: "100%", height: "85%" }}
              >
                Secure your spot at Techkriti, IIT Kanpur, by completing the festival fee payment. <br/>
                <b>The festival fee amounts to INR 2500/- per participant.</b> It will include the passes to Tech Talks, Exhibitions, Auto Expo, Defense Expo, EDM Night, Bollywood Night, Comedy Night, Panel Discussions, Creators Conclave, Tech Summit and Sustainability Summit. Also you will get <b>Accommodation during the festival dates from 14th-17th March inside the campus of IIT Kanpur</b>.

                Please carefully review the fee payment guidelines outlined in this document: <a href="https://docs.google.com/document/d/1TJMsXGkI8rawhjoMteOPcmSv6MLRg6N1YrEAJPE3tL4/edit?usp=sharing" target="_blank">How to make Payment</a><br/>
                Link of payment portal: <a href = "https://www.onlinesbi.sbi/sbicollect/icollecthome.htm" target="_blank">https://www.onlinesbi.sbi/sbicollect/icollecthome.htm</a><br/>
                
                <b>Payment Deadline: </b><span style = {{"color":"#26c1e0"}}>16th February, 2024</span><br/>
                Once payment is completed, upload the receipt here (compulsory for payment confirmation). Our team will confirm your payment within one day. Ensure you regularly check your email and our website for payment updates.<br/>
	  <b>Note- Every participant must upload the payment receipt here after completing the payment. Even if you have paid the amount collectively for all your team members then everyone must upload the same receipt here for their individual payment verification.</b><br/>

                {currentUserInfo?.feepaid === 1 && <CompetitionFee />}
                {/* {currentUserInfo?.competition.length !== 0 && currentUserInfo?.feepaid === 1 &&
                  <CompetitionFee />} */}
                {currentUserInfo?.competition.length !== 0 && currentUserInfo?.feepaid === 2 && <h2>Fee receipt pending for Verification</h2>}
                {currentUserInfo?.competition.length !== 0 && currentUserInfo?.feepaid === 3 && <><h2>Fee receipt rejected.. Upload again</h2>
                  <CompetitionFee /></>
                }
                {currentUserInfo?.competition.length !== 0 && currentUserInfo?.feepaid === 4 && <h2>Receipt Verified..</h2>}

              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={3}
              className="regCompTabpanel"
              style={{ height: "80%", width: "100%", overflow: "auto" }}
            >

              <div
                className="profile-contest-details-inner"
                style={{ width: "100%", height: "85%" }}
              >
                Secure your spot in the workshop by promptly paying the workshop fee, as seats are limited.
 <br/>
                Please carefully review the fee payment guidelines outlined in this document: <a href="https://docs.google.com/document/d/1TJMsXGkI8rawhjoMteOPcmSv6MLRg6N1YrEAJPE3tL4/edit?usp=sharing" target="_blank">How to make Payment</a><br/>
                <b>Payment Deadline: </b><span style = {{"color":"#26c1e0"}}>16th February, 2024</span><br/>
                After completing the payment, upload the corresponding workshop receipts for every workshop you've registered for (compulsory for payment confirmation). Our team will confirm your payment within one day. Please ensure you regularly check your email and our website for payment updates
                <br/><br/>
                <h2
                  className="profile-contestDetailHead"
                  style={{ fontSize: "25px" }}
                >
                  You are registered in the following workshops.
                </h2>
                <div
                  className="regCompTabpanel"
                  style={{ height: "80%", overflow: "auto", padding: "5px" }}
                >
                  {!loading && currentUserInfo?.workshop?.map((e, ind) => {
                    return (
                      <>
                        <h4 className="regcompProfileHead">
                          {++ind}. <span style={{ color: "white" }}>{e[0]}</span>{e[1] === "false" && <><Workshopfee wname={e[0]} /> </>}
                          {e[1].startsWith("pending") && <> Receipt pending for verification </>}
                          {e[1].startsWith("rejected") && <> Receipt Rejected <Workshopfee wname={e[0]} /> </>}
                          {e[1].startsWith("accepted") && <> Payment Done </>}
                          <br />
                        </h4>
                      </>
                    );
                  })}
                </div>
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
}
